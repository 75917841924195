@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --color-primary: #161b33;
  --color-primary-200: #a0a1ad; /* new ui : default primary-200 */
  --color-primary-900: #161b33;
  --color-primary-600: #52566d;

  --color-grey: #f6f4f3;

  /* ------ old colors */
  /* Primary */
  --color-primary-50: #eaeaee;
  --color-primary-soft: #eff2fa;
  --color-primary-100: #d3d4dc;
  --color-primary-300: #a8b1b7;
  --color-primary-400: #4761c8; /* new ui : default primary-400 */
  --color-primary-500: #161b33; /* new ui : default primary */
  /* --color-primary-600: #1a1d3b; */
  --color-primary-700: #121428;
  --color-primary-800: #090a14;
  --color-primary-1000: #294f5d;
  --color-primary-1100: #04182b;

  /* Secondary */

  --color-secondary-50: #faf8f2;
  --color-secondary-100: #f4efe5;
  --color-secondary-200: #e9e0ca;
  --color-secondary-300: #ddd0b0;
  --color-secondary-400: #d2c195;
  --color-secondary-500: #4761c8;
  --color-secondary-600: #4761c8;
  --color-secondary-700: #64593e;
  --color-secondary-800: #322c1f;
  --color-secondary-900: #14120c;

  /* Tertiary */
  --color-tertiary: #ebe7e5;
  --color-tertiary-100: #f3f4fd;
  /* WC COLOR */

  /* Light */
  --color-light: rgba(128, 159, 184, 100%);

  /* Dark */
  --color-dark: rgba(6, 21, 43, 100%);
  --color-dark-100: #04182b;

  /* Red */
  --color-red-500: #c84747;

  /* Danger */
  --color-danger: #de4545;
  --color-danger-100: #facbc5;
  --color-danger-200: #ff00008a;
  --color-danger-300: #ff00006a;
  --color-danger-400: #ff000025;
  --color-danger-500: #960000;

  /* Success */
  --color-success: #2cb573;
  --color-success-100: #9feda4;
  --color-success-200: #007f008a;
  --color-success-300: #007f009a;
  --color-success-400: #007f0025;
  --color-success-500: #009600;

  /* Warning */
  --color-warning: rgba(249, 185, 89, 100%);

  /* Gray */
  /* Slate */
  --color-slate-600: #52566d;
  --color-slate-900: #161b33;

  /* new colors */
  --color-gray-400: rgb(58, 68, 84);
  --color-gray-800: rgb(27, 32, 39);
  --color-gray-900: #787a7e;
  --color-gray-1000: #e5ebf4;
  --color-gray-1100: #4f4f50;
  --color-gray-1200: #eef1f6;
  --color-gray-1300: #f8f9fb;
  --color-gray-1500: #cad7e9;

  /* White */
  --color-white: #ffffff;
  --color-white-500: #f9fafe;
  --color-white-400: rgb(218, 225, 230);

  /* Blue */
  --color-blue-400: rgb(124, 178, 247);

  /* Gradient priamry */
  --color-gradient-primary: linear-gradient(
    90deg,
    --color-primary 0%,
    --color-primary-40 100%
  );

  /* Gradient secondary */
  --color-gradient-secondary: linear-gradient(
    90deg,
    --color-secondary 0%,
    --color-secondary-40 100%
  );

  --color-bankAccounts: rgb(172, 135, 127);
  --color-stocks: rgb(143, 142, 220);
  --color-crypto: rgb(221, 109, 147);
  --color-realEstate: #77d7d6;
  --color-lifeInsurance: #87da72;
  --color-savings: #d7a58e;
  --color-pension: #e98dac;
  --color-crowdfunding: #aad7c0;
  --color-incomeTaxes: #9ada8c;
  --color-other: #e9c78d;

  --backgroundWidget: #f8f9fb;
  --borderBgWidget: #eaeaea;

  /* NEW UI COLORS BELOW */
  --v2-color-blue-50: #eff6ff;
  --v2-color-blue-1000: #2c4ed8;
}

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

body {
  margin: 0;
  font-family: "DM Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.ucFirst::first-letter {
  text-transform: uppercase;
}
.react-resizable {
  width: 100% !important;
}

.my-sidebar .membre-info {
  display: none;
}
.premium-btn {
  background: linear-gradient(
    90deg,
    #f2d394 3.68%,
    #e4ba64 71.91%,
    #dfa735 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.nav-active {
  color: var(--color-secondary);
}

.nav-list:hover {
  color: var(--color-secondary);
}

.fill-arrow-menu {
  fill: var(--color-primary-200);
  width: 20px;
}

.my-nav-menu .mynav .leading-normal.active .block {
  animation: opacity 0.3s ease-in-out;
}

@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.my-nav-menu li a {
  color: var(--color-primary-300);
  font-weight: 500;
}

.my-nav-menu li a:last-child {
  border-image: none;
}

.my-nav-menu li.active a {
  color: white;
  font-weight: 500;
}

.my-nav-menu li.active .block li a {
  color: var(--color-primary-300);
}

.my-nav-menu li.active .block li.active a {
  color: white;
}

.my-nav-menu li.active .block li.active {
  background: none;
}

.my-nav-menu li .block li a:last-child {
  border-image: none;
}

.my-nav-menu li {
  padding-left: 20px;
}

.premium-menu-active,
.my-nav-menu li.active {
  background-image: url(/public/svg/borderLeftLi.svg);
  background-repeat: no-repeat;
  background-size: 5px;
  background-position: left top;
}

.yellow-dropshadow {
  box-shadow: 4px 4px 16px rgba(149, 133, 92, 0.33);
}

.edit-button {
  z-index: 1;
}
#favorite-assets,
#favorite-account {
  max-height: 260px !important;
}

.boxShadowCard {
  background: white;
  border-radius: 10px;
}

/* =================== EXTERNAL =================== */
/* visx */
.controls {
  font-size: 13px;
  line-height: 1.5em;
}

.controls > div {
  margin-bottom: 4px;
}

label {
  font-size: 12px;
  margin: 0 0.2rem;
}

label input {
  margin: 0 0.2rem;
}

progress::-webkit-progress-bar {
  background-color: #ebe7e5;
}
progress::-webkit-progress-value {
  background-color: var(--color-asset);
}
#investorProfileProgress::-webkit-progress-value {
  background-color: var(--color-primary-400);
}

/* input[type="radio"] {
  height: 10px;
} */
.pattern-lines {
  position: absolute;
  pointer-events: none;
  opacity: 0;
}

tspan {
  fill: var(--color-primary);
}

/* background */
.bg-bankAccounts {
  background-color: var(--color-bankAccounts);
}

.bg-stocks {
  background-color: var(--color-stocks);
}

.bg-crypto {
  background-color: var(--color-crypto);
}

.bg-realEstate {
  background-color: var(--color-realEstate);
}

.bg-lifeInsurance {
  background-color: var(--color-lifeInsurance);
}

.bg-savings {
  background-color: var(--color-savings);
}

.bg-pension {
  background-color: var(--color-pension);
}

.bg-crowdfunding {
  background-color: var(--color-crowdfunding);
}

.bg-incomeTaxes {
  background-color: var(--color-incomeTaxes);
}

.bg-other {
  background-color: var(--color-other);
}
.card-boxShadow {
  box-shadow: 5px 5px 12px rgba(167, 169, 185, 0.32);
}

/* colors */
.text-bankAccounts {
  color: var(--color-bankAccounts);
}

.text-stocks {
  color: var(--color-stocks);
}

.text-crypto {
  color: var(--color-crypto);
}

.text-realEstate {
  color: var(--color-realEstate);
}

.text-lifeInsurance {
  color: var(--color-lifeInsurance);
}

.text-savings {
  color: var(--color-savings);
}

.text-pension {
  color: var(--color-pension);
}

.text-crowdfunding {
  color: var(--color-crowdfunding);
}

.text-incomeTaxes {
  color: var(--color-incomeTaxes);
}

.text-other {
  color: var(--color-other);
}

.inset-effect {
  background-color: #565656;
  color: transparent;
  text-shadow: 0.5px 0.5px 1px rgba(199, 177, 121, 0.5);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
}

.svg-container > span {
  color: currentColor;
  width: inherit;
  height: inherit;
}

.svg-container.adaptFillColor > span > svg {
  fill: currentColor;
}

.fill-black path {
  fill: black;
}

.svg-container.adaptStrokeColor > span > svg {
  stroke: currentColor;
}

svg {
  margin: auto;
}

.close {
  width: 20px;
  height: 20px;
  margin: 40px;
  fill: black;
}

.close:hover {
  fill: green;
}

.cursor-move {
  padding: 0 !important;
  background-color: transparent !important;
}

.slider_width {
  /* margin: auto; */
  margin-right: 20.8%;
}

@media only screen and (max-width: 1250px) {
  .slider_width {
    margin-right: 8%;
  }
  .first-arrow {
    margin-left: 15px;
    z-index: 5 !important;
  }
  .last-arrow {
    /* margin-right: 15px; */
    z-index: 5 !important;
  }
}

.slider_width .boxShadowCard {
  border: 0;
}

.bold {
  font-weight: bold;
}

/* bg */

/* slider */
.slick-list {
  border-radius: 8px;
  overflow: hidden;
}
.slick-track {
  display: flex;
  margin-bottom: 8px;
  gap: 20px;
}
/* Range */
.range-slider {
  -webkit-appearance: none;
  width: 100%;
  height: 22px;
  background: #d3d3d3;
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  padding: 0 0.2rem;
  /* background: linear-gradient(
    270deg,
    var(--color-secondary-500) 0%,
    var(--color-primary-500) 100%
  ); */
  background: linear-gradient(270deg, #294f5d 0%, #04182b 100%);
  border-radius: 8px;
}

.range-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 6px;
  height: 17px;
  background: #f3f4fd;
  border-radius: 26px;
  cursor: pointer;
}

.range-slider::-moz-range-thumb {
  width: 6px;
  height: 17px;
  background: #f3f4fd;
  border-radius: 26px;
  cursor: pointer;
}

.container {
  padding: 25px 0px;
  background: #eef1f6;
}

@media only screen and (max-width: 1350px) {
  .container {
    margin: auto;
    max-width: 95%;
  }
}

@media only screen and (min-width: 1250px) {
  .container {
    max-width: 98%;
    margin: auto;
  }
}

.transparent-bg {
  background: transparent;
}

.background-base {
  background: #edf2f8;
}

.valueAccordeon {
  margin-right: 10px;
}

.boutonPremium {
  display: none;
  place-content: center;
  padding-left: 0;
}

.boutonPremium h3 {
  display: none;
  padding-left: 0;
}

.boutonPremium img {
  max-width: 225px;
}

@media only screen and (min-width: 1250px) {
  .boutonPremium img {
    max-width: 190px;
  }
}

.boutonDeconnecter {
  place-content: center;
  border: 1px solid #cad7e9;
  width: fit-content;
  margin: auto;
  padding: 10px 20px;
  border-radius: 5px;
}

.boutonDeconnecter img {
  display: none;
}

.boutonDeconnecter h3 {
  border: 0;
}

.premiumMenuLink {
  place-content: center;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  background: linear-gradient(#c6b17a, #aa8f48);
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 5px;
  margin-top: 35px;
}

.premiumMenuLink a {
  color: white;
  font-size: 16px;
  font-weight: 500;
}

.my-nav-menu {
  overflow-x: hidden;
}

.my-nav-menu li ul .premiumMenuLink {
  display: none;
}

.my-nav-menu li ul {
  padding-left: 15px;
}

.menuArrowUp {
  height: 25px;
}

/* CONTENT CARD ADD WALLET */

.wallet-grid .wallet-card {
  display: flex;
  flex-direction: column;
  border: none;
  padding: 1rem 0rem;
}

.icon-add-wallet {
  fill: var(--color-secondary-500);
}

.content-wallet-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.card-boxShadow.wallet-card {
  box-shadow: rgb(0 0 0 / 19%) 0px 10px 20px, rgb(0 0 0 / 23%) 0px 6px 6px;
  background-color: white;
}

.cards-premium .wallet-card,
.cards-premium .wallet-card {
  background: none;
  background: linear-gradient(#294f5d, #04182b);
}

.iconCard {
  width: auto;
  height: 2.5rem;
}

.percentHeader {
  align-items: center;
  display: flex;
}

.percentHeader span {
  width: 0;
}

.percentCard p {
  display: flex;
  align-items: center;
  color: white;
}

.percentCardText {
  font-size: 11px;
  font-weight: 300;
}

.percentCardTopRight p svg {
  width: 14px;
  margin-right: 5px;
}

.percentCardTopRight p .fillSuccess path {
  fill: #72bb37;
}

.percentCardTopRight p .fillNegative path {
  fill: #ff5a5a;
}

.rotateX-180 {
  transform: rotate(65deg);
}

.position-inherit {
  position: inherit;
}

/* BANDEAU HEADER RESPONSIVE */

@media only screen and (max-width: 550px) {
  .card-boxShadow {
    padding: 25px 50px;
    width: 375px;
    height: 250px;
    flex-direction: column;
    place-content: center;
  }
}

@media only screen and (max-width: 425px) {
  tbody {
    align-items: center;
  }

  .card-boxShadow {
    padding: 25px 50px;
    width: 350px;
    height: 200px;
    flex-direction: column;
    place-content: center;
  }
}

@media only screen and (max-width: 375px) {
  .card-boxShadow {
    padding: 10px 10px;
    width: 300px;
    height: 150px;
    flex-direction: column;
    place-content: center;
  }
}

/* ================================================================== */
/* ===============        MYSIDEBAR        ========================== */
/* ================================================================== */

.my-sidebar {
  box-shadow: 0px 0.5rem 1rem 0rem rgb(0 0 0 / 20%);
}

/* .my-sidebar .username {
  font-size: 16px;
} */

/* ================================================================== */
/* =================        HOME        ============================ */
/* ================================================================== */

@media only screen and (max-width: 1350px) {
  .w-full.boxShadowCard.repartition {
    min-width: 40%;
    overflow-y: scroll;
  }
}

.repartition .with-wallet-mychart {
  padding-top: 0;
}

.repartition .border-t-secondary-500 {
  border-top: 0;
}

.portefeuille h2 {
  display: none;
}

/* ============================================================== */
/* ==================  FORM ADD WALLET ========================== */
/* ============================================================== */

.mygrid-row {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  place-content: center;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--color-primary-50);
}

/* ============================================================== */
/* =============================================================== */
/* ============================================================== */

.addWallet-actif .css-1pahdxg-control {
  background: var(--color-primary-200);
  color: white;
  border: 0;
  border-radius: 0px;
  border-bottom: 1px solid var(--color-primary-200);
  box-shadow: unset;
}

.addWallet-actif .css-1pahdxg-control:hover {
  border-bottom: 1px solid var(--color-primary-200);
}

.addWallet-actif .css-26l3qy-menu {
  background: var(--color-primary-50);
  color: var(--color-primary-200);
  border: 0;
  border-radius: 0px;
  border-bottom: 1px solid var(--color-primary-50);
  z-index: 6;
}

.addWallet-actif .css-26l3qy-menu div div {
  background: transparent;
}

.addWallet-actif .css-26l3qy-menu div div:hover {
  background: var(--color-primary-200);
  color: white;
}

.addWallet-actif .css-6j8wv5-Input {
  color: white;
  border: 0;
  margin: 0;
  padding-bottom: 0;
}

.addWallet-actif [type="text"]:focus {
  box-shadow: unset;
  color: var(--color-primary-500);
}

.addWallet-actif [data-value] {
  color: var(--color-primary-500);
}

.addWallet-actif .css-qc6sy-singleValue {
  color: var(--color-primary-500);
}

.addWallet-actif button.bg-primary-100.btn--animated.btn--border.btn--primary {
  background: transparent;
  color: var(--color-primary-500);
  display: flex;
  place-content: center;
  align-items: center;
  border: 1px solid var(--color-primary-500);
  padding: 10px 80px;
}

.back-button {
  display: none;
  color: white;
}

.back-button svg {
  width: 2rem;
}

.header-actif p {
  display: flex;
  place-content: center;
  align-items: center;
  color: var(--color-secondary-500);
  text-transform: capitalize;
  font-size: 30px;
  margin-bottom: 35px;
}

.cardIsOpen .bandeau-header {
  display: none;
}

.icon-accordeon img {
  width: 10px;
  transform: rotate(270deg);
}

.crypto-icon {
  height: 23px;
  width: auto;
}

.radio-input {
  flex-direction: column;
}

.radio-input {
  width: 100%;
  align-items: center;
}

@media only screen and (max-width: 1000px) {
  .radio-input {
    width: 100%;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: inherit;
  }
}

@media only screen and (max-width: 700px) {
  .radio-input {
    padding-bottom: 0;
    padding-top: 0;
  }
}

.radio-input:first-child {
  border-bottom: 0;
}

.radio-input:first-child .items-start {
  width: auto;
  margin: 0;
}

.radio-input .label-champ-mygrid {
  color: var(--color-secondary-500);
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 30px;
}

@media only screen and (max-width: 1000px) {
  .radio-input .label-champ-mygrid {
    width: 100%;
    margin-bottom: 20px;
    text-align: center;
  }
}

.radio-input:last-child {
  border-bottom: 0;
}

.my-modal .mygrid-row {
  flex-wrap: wrap;
}

.my-modal .input-form-margin {
  width: 47%;
  margin: 0;
  padding: 0;
  margin-top: 15px;
}

.my-modal .mygrid.modal-form .retour-form {
  display: none;
}

.my-button,
.add-form-button button,
.add-form-button .my-button[type="button"],
.add-form-button .my-button[type="reset"],
.add-form-button .my-button[type="submit"] {
  background-color: transparent !important;
  transition: 0.5s;
  border: 1px solid white;
  border-radius: 5px;
  padding: 10px 35px;
  color: white;
}

.rotate-goutte {
  transform: rotate(270deg);
}

.retour-form {
  display: flex;
  place-content: center;
  font-weight: 500;
  font-size: 16px;
  padding: 10px 25px;
  background: var(--color-primary-500);
  color: white;
  border-radius: 5px;
  margin-bottom: 20px;
  transition: 0.5s;
}

@media only screen and (max-width: 550px) {
  .retour-form {
    font-size: 14px;
    padding: 10px 25px;
    margin-bottom: 20px;
  }
}

.retour-form:hover {
  transform: scale(1.05);
  transform: translateY(-2px);
  transition: 0.5s;
}

.my-button:hover {
  transform: scale(1.05);
  transform: translateY(-2px);
}

/* MODAL */

.close-modal {
  font-weight: bold;
  font-size: 25px;
  color: var(--color-primary-500);
  padding-right: 1rem;
  padding-left: 1rem;
  z-index: 5;
}

.modal-form {
  position: relative;
}

.my-modal.modal {
  max-width: 80%;
  width: 55%;
  padding: 0;
}

@media only screen and (max-width: 1250px) {
  .my-modal.modal {
    max-width: 80%;
    width: 75%;
  }
}

@media only screen and (max-width: 1000px) {
  .my-modal.modal {
    max-width: 95%;
    width: 90%;
  }
}

@media only screen and (max-width: 400px) {
  .my-modal.modal {
    max-width: 95%;
    width: 90%;
  }
}

.with-wallet-mychart {
  padding: 15px;
}

@media only screen and (max-width: 450px) {
  .with-wallet-mychart {
    width: 100%;
  }
}

.submit-nom-compte button {
  padding: 0rem 0.5rem;
  background: transparent;
  margin: 0;
  transition: 0.5s;
}

.submit-nom-compte button:hover {
  transform: scale(1.2);
  transition: 0.5s;
}

.submit-nom-compte button img {
  height: 20px;
  width: auto;
}

.modifier-nom-compte .after-arrow::after {
  background-image: url(/public/fleche.svg);
  background-size: cover;
  display: inline-flex;
  width: 50px;
  background-repeat: no-repeat;
  height: 50px;
  content: "";
  transform: rotate(310deg);
}

@media only screen and (max-width: 700px) {
  .after-arrow {
    font-size: 14px;
    position: relative;
  }
  .modifier-nom-compte .after-arrow::after {
    position: absolute;
    bottom: 35px;
  }
  .my-modal .mygrid.modal-form input {
    margin-left: 0;
  }
  .submit-nom-compte {
    z-index: 5;
  }
}

@media only screen and (max-width: 350px) {
  .modifier-nom-compte .after-arrow::after {
    position: absolute;
    bottom: 60px;
    right: 10px;
    width: 35px;
    height: 35px;
  }
}

@media only screen and (max-width: 400px) {
  .modifier-nom-compte .after-arrow::after {
    position: absolute;
    bottom: 40px;
    right: 20px;
    width: 40px;
    height: 40px;
  }
}

@media only screen and (max-width: 375px) {
  .modifier-nom-compte .after-arrow::after {
    position: absolute;
    bottom: 55px;
    right: 20px;
    width: 40px;
    height: 40px;
  }
}
.my-modal .section-action-button {
  margin-top: 30px;
}

.section-action-button {
  display: flex;
  place-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
}

@media only screen and (max-width: 700px) {
  .section-action-button {
    display: flex;
    place-content: center;
    margin-top: 10px;
  }
}

.section-action-button button {
  margin: 5px 10px;
  transition: 0.5s;
}

.section-action-button button:hover {
  transition: 0.5s;
  margin: 5px 10px;
  transform: translateY(-2px);
}

.my-modal .mygrid.modal-form .mygrid-row {
  place-content: center;
}

.my-modal .mygrid.modal-form input {
  text-overflow: ellipsis;
  background: #edf2f8;
  color: var(--color-primary-200);
  font-weight: 600;
  backdrop-filter: blur(30px);
  height: 46px;
  padding: 16px;
  border-radius: 3px;
  transition: all 0.2s ease-in-out;
  border: 1px solid var(--color-primary-200);
}

.my-modal .input-form-margin .flex {
  border: 0;
  box-shadow: 0 0 0;
  margin: auto;
}

.my-grid.modal-form {
  gap: 0rem;
}

.my-modal .input-form-margin input {
  font-size: 20px;
  font-weight: bold;
  width: 50%;
  margin: auto;
  text-align: center;
  background: inherit;
  color: var(--color-primary-500);
  padding: 10px;
}

@media only screen and (max-width: 700px) {
  .my-modal .input-form-margin input {
    font-size: 18px;
  }
}

.my-modal .input-form-margin input[type="date"],
.my-modal .input-form-margin input[name="value"] {
  color: var(--color-primary-200);
  font-size: 15px;
  font-weight: normal;
  text-align: left;
}

.my-modal .mygrid.modal-form {
  background: inherit;
  padding: 0;
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin: auto;
  position: relative;
  padding-bottom: 25px;
}

.my-modal .input-form-margin label {
  position: absolute;
  color: var(--color-secondary-500);
  background: #edf2f8;
  width: fit-content;
  font-size: 14px;
  padding: 0 15px;
  top: -10px;
}

.my-modal .input-form-margin select {
  background: inherit;
  color: var(--color-primary-200);
  margin: 2px;
}

.mygrid.modal-form .input-form-margin .flex {
  border: 0px;
}

.my-modal .mygrid.modal-form {
  display: block;
}

@media only screen and (max-width: 700px) {
  .my-modal .mygrid.modal-form {
    margin-top: 45px;
    margin-bottom: 20px;
    padding: 1rem 1.5rem;
    border-radius: 10px;
    padding-bottom: 0;
    border: 0;
  }
  .submit-nom-compte {
    top: 35px;
    right: 20px;
  }
}

.submit-nom-compte {
  position: absolute;
  top: 40px;
  right: 100px;
}

@media only screen and (max-width: 1250px) {
  .submit-nom-compte {
    top: 40px;
    right: 60px;
  }
  .submit-nom-compte .button-modal {
    padding: 10px 20px !important;
  }
}

@media only screen and (max-width: 1000px) {
  .submit-nom-compte {
    top: 45px;
    right: 80px;
  }
  .submit-nom-compte .button-modal {
    padding: 5px 10px !important;
  }
}

@media only screen and (max-width: 700px) {
  .submit-nom-compte {
    top: 45px;
    right: 95px;
  }
}

@media only screen and (max-width: 400px) {
  .submit-nom-compte {
    top: 45px;
    right: 30px;
  }
}

.first-table h3,
.votre-solde h3 {
  color: var(--color-secondary-500);
  font-size: 18px;
  margin-top: 0;
}

@media only screen and (max-width: 700px) {
  .first-table h3,
  .votre-solde h3 {
    color: var(--color-secondary-500);
    font-size: 18px;
    text-align: center;
  }
}

.votre-solde table tbody,
.first-table table tbody {
  background: transparent;
}

@media only screen and (max-width: 550px) {
  .first-table table tbody {
    max-width: 100%;
  }
}

@media only screen and (max-width: 400px) {
  .first-table table tbody {
    max-width: 90%;
  }
}

@media only screen and (max-width: 350px) {
  .first-table table tbody {
    max-width: 75%;
  }
}

.votre-solde table tr,
.first-table table tr {
  color: var(--color-primary-500);
}

.votre-solde table th,
.first-table table th {
  color: var(--color-primary-500);
}

.votre-solde table tr td,
.first-table table tr td {
  color: var(--color-primary-500);
}

.votre-solde table tbody,
.first-table table tbody {
  border: 0;
}

.my-swiper-arrow {
  background-color: inherit;
  top: -5px;
}

.my-swiper-arrow svg {
  fill: var(--color-primary-500);
  height: 35px;
  width: 35px;
}

.textInSliderCard {
  margin-bottom: 5px;
  margin-right: 50px;
}

.tableBorder tr {
  border-bottom: 1px solid var(--color-primary-50);
  border-radius: 0;
}

.tableBorder tr:last-child {
  border-bottom: 0;
  border-radius: 0;
}

.border-bottom {
  border-bottom: 1px solid var(--color-primary-50);
  border-radius: 0;
}

.cardPriceSuccess {
  font-size: 12px;
  color: white;
}

.cardPriceNegative {
  font-size: 12px;
  color: white;
}

.my-modal {
  background-size: 8px;
  display: flex;
  align-items: center;
}

/* =====================================================  */
/* ============== RESPONSIVE TABLE MODAL ===============  */
/* =====================================================  */

@media only screen and (max-width: 700px) {
  .my-modal table thead {
    display: none;
  }

  .my-modal table tr {
    flex-direction: column;
    padding-left: 15px;
  }

  .my-modal table td {
    display: block;
    text-align: right;
  }

  .my-modal table td:before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
  }

  .my-td {
    margin: auto;
    width: auto;
    padding: 0;
    right: 0;
  }
}

@media only screen and (max-width: 425px) {
  .my-td {
    margin-left: 0;
    width: auto;
    padding: 0;
    right: 0;
  }
}

/* =====================================================  */
/* ====================== LOGIN  ======================  */
/* =====================================================  */

.login {
  background: #eef1f6;
}

.login .input-form-margin input {
  font-weight: normal;
  text-align: left;
  background-color: #fff;
  border-color: #6b7280;
  border-width: 1px;
}

.login .input-form-margin {
  margin: 0;
}

.login .mdp-oublie {
  display: flex;
  align-items: center;
}

/* =================================================================== */
/* ========================= APEX CHAR DESIG ========================== */
/* =================================================================== */

.my-chart .apexcharts-legend,
.my-chart .apexcharts-toolbar,
.apexcharts-yaxis-inversed,
.apexcharts-xaxis-tick {
  display: none;
}

.slider_width .absolute {
  z-index: 0;
}

/* =================================================================== */
/* ========================= SIDEBAR MOBILE ========================== */
/* =================================================================== */

@media only screen and (max-width: 1250px) {
  .dc-prenium {
    align-items: center;
  }

  .dc-prenium .yellow-dropshadow {
    padding-left: 40px;
    padding-right: 75px;
  }
}

@media only screen and (max-width: 1000px) {
  .dc-prenium .yellow-dropshadow {
    padding-left: 15px;
    padding-right: 35px;
  }
}

@media only screen and (max-height: 900px) {
  .my-nav-menu a {
    padding-top: 10px;
    padding-bottom: 5px;
  }
}

/* =================================================================== */
/* ========================= SIDEBAR MOBILE ========================== */
/* =================================================================== */

.text-danger,
.text-success {
  font-weight: bold;
}

/* =================================================================== */
/* ========================= MY BAR CHART ========================== */
/* =================================================================== */

.w-full.boxShadowCard.repartition::-webkit-scrollbar {
  display: none;
}

.overflow-y-auto::-webkit-scrollbar {
  display: none;
}
/* =================================================================== */
/* ===================== SWITCHER BUTTON BARCHART ===================== */
/* =================================================================== */

.switcherBarchart__button,
.periodButton {
  display: flex;
  border-radius: 2px;
  align-items: center;
  background: #e5ebf4;
  padding: 5px 15px;
  color: #7d90ad;
}

.periodButton {
  margin-right: 5px;
  margin-left: 5px;
}

.switcherBarchart__button:last-child {
  display: flex;
  background: #e5ebf4;
  padding: 5px 15px;
  color: #7d90ad;
}

.switcherBarchart__button.active,
.periodActive {
  background: var(--color-primary-500);
  color: white;
}

.switcherBarchart__button:last-child.active {
  border-radius: 3px;
  background-color: var(--color-primary-500);
  color: white;
  display: flex;
  align-items: center;
  padding: 5px 15px;
}

.plus-value {
  display: flex;
}

.plus-value h2 {
  display: none;
}

.separator {
  width: 0.5px;
  height: 20px;
  background: white;
  margin-right: 10px;
  margin-left: 10px;
}

/* .etiquetteSuccess.opac {
  background: #72bb3742;
}

.etiquetteNegative.opac {
  background: #ff5a5a57;
} */

.etiquetteSuccess svg,
.etiquetteNegative svg,
.etiquetteSuccess2 svg,
.etiquetteNegative2 svg {
  width: 16px;
  margin-right: 10px;
}

.etiquetteNegative svg path,
.etiquetteNegative2 svg path {
  fill: white;
}

.etiquetteSuccess svg path,
.etiquetteSuccess2 svg path {
  fill: white;
}

.etiquetteNegative {
  background-color: #ff00008a;
  color: #facbc5;
}

.etiquetteSuccess {
  background-color: #007f008a;
  color: #9feda4;
}

.etiquetteSuccess2 {
  display: flex;
  background: #72bb37;
  padding: 5px 15px;
  border-radius: 20px;
  align-items: center;
  margin-right: 10px;
}

.etiquetteNegative2 {
  display: flex;
  background: #ff5a5a;
  padding: 5px 15px;
  border-radius: 20px;
  align-items: center;
  margin-right: 10px;
}

.percent {
  height: 17px;
}

.text-green-success {
  color: var(--color-success);
}

.doubleArrow {
  display: none;
}

.bannerHome .doubleArrow {
  display: block;
  visibility: hidden;
}

.header-price {
  line-height: 60% !important;
}

/* =================================================================== */
/* ============================ EMPRUNT ============================== */
/* =================================================================== */

.boxShadowCard.etat-avancement .bg-white {
  background: inherit;
}

/* =================================================================== */
/* ============================ PREMIUM FORM ============================== */
/* =================================================================== */

.premium-form {
  gap: 0;
}

.premium-form .label-champ-mygrid {
  margin-bottom: 20px;
  width: 100%;
  color: var(--color-secondary-500);
  font-weight: 600;
  font-size: 16px;
}

.premium-form .section-action-button button {
  margin: 5px 10px;
  transition: 0.5s;
}

.premium-form .section-action-button button:hover {
  transition: 0.5s;
  transform: translateY(-2px);
}

/* =================================================================== */
/* =================================================================== */
/* =================================================================== */

/* =================================================================== */
/* ============================ PREMIUM MODAL ========================== */
/* =================================================================== */

.modal-premium .retour-form {
  display: none;
}

.modal-premium .section-action-button button:last-child {
  display: flex;
  place-content: center;
  font-weight: 500;
  font-size: 16px;
  padding: 10px 25px;
  background: linear-gradient(#c6b17a, #aa8f48);
  border-radius: 5px;
  margin-bottom: 20px;
  color: white;
  border: 0;
}

.modal-premium .grid-cols-2 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.modal-premium .label-champ-mygrid {
  width: 100%;
  color: var(--color-secondary-500);
  font-weight: 600;
  font-size: 16px;
}

.modal-premium .border-primary-200 {
  margin-top: 5px;
}

/* =================================================================== */
/* ========================= CARDS BEFORE FORM ======================= */
/* =================================================================== */

.cards-mo {
  position: relative;
  top: -120px;
  z-index: 5;
  width: fit-content;
  margin: auto;
  display: flex;
}

@media only screen and (max-width: 550px) {
  .cards-mo {
    flex-direction: column;
    top: 0;
  }
}

.card {
  padding: 20px;
  margin-right: 10px;
  margin-left: 10px;
  background: linear-gradient(#294f5d, #04182b);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  cursor: pointer;
}

@media only screen and (max-width: 550px) {
  .card {
    margin-bottom: 10px;
  }
}

.synchroniser {
  transition: 0.5s;
}

.synchroniser:hover {
  transition: 0.5s;
  transform: translateY(-2px);
  background: linear-gradient(#c6b17a, #aa8f48);
}

.card-manuelle-active {
  background: linear-gradient(#04192b, #294f5d);
}

.card-manuelle-active .synchroniser {
  background: linear-gradient(#c6b17a, #aa8f48);
}

@media only screen and (min-width: 1000px) {
  .card.sm_md\:w-80 {
    width: 29rem;
  }
}

.populaire {
  transform: translate(-50%, -50%);
}

.my-second-size {
  font-size: 16px;
}

.mysize {
  font-size: 28px;
}

/* =========================================================== */
/* ===================== I NET/BRUT =========================== */
/* =========================================================== */

.custom-class {
  border: none !important;
  border-radius: 12px !important;
}

.css-13cymwt-control {
  border-width: 0;
  border-radius: 12 !important;
}
